<template>
    <el-container>
        <el-header class="header">
            <router-link to="/main" class="logo">
                <img src="../../../assets/mlogo.png" alt="logo">
                {{title}}
            </router-link>
            <div class="head">
            </div>
            <div class="user">
                <el-dropdown trigger="click">
                    <span class="username">
<!--                      <img src="//cdn.zhack.com/zkui/img/avatar.png" class="user-avatar"/>-->
                       {{$store.getters.username}}
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <router-link class="link" to="/main">
                            <el-dropdown-item>首页</el-dropdown-item>
                        </router-link>
                        <router-link class="link" to="/main/password">
                            <el-dropdown-item>修改密码</el-dropdown-item>
                        </router-link>
                        <router-link class="link" to="/logout">
                            <el-dropdown-item divided>退出登陆</el-dropdown-item>
                        </router-link>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </el-header>
        <el-container>
            <slot></slot>
        </el-container>
    </el-container>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: '佐相科技'
            }
        },
        methods: {
            doLogout() {
                this.$router.push({path: '/'})
            },
            handleOpen(key, keyPath) {
                console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                console.log(key, keyPath);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .header {
        background: #2081c0;
        color: #fff;
        display: flex;
        flex-direction: row;
        height: 60px;
        line-height: 60px;

        .logo {
            width: 240px;
            vertical-align: middle;
            display: flex;
            align-items: center;
            color: #ffffff;
            text-decoration: none;

            img {
                width: 35px;
                height: 35px;
                border-radius: 5px;
                margin-right: 10px;
            }
        }

        .head {
            flex: 1;
        }

        .user {
            text-align: right;
            width: 180px;

            .username {
                color: #ffffff;
                cursor: pointer;
                vertical-align: middle;
                display: flex;
                align-items: center;

                .user-avatar {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

    .link {
        text-decoration: none;
    }
</style>
